import React,{useEffect,useState} from 'react'
import { Link } from "react-router-dom";
import Axios from 'axios';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from "react-router-dom";
import { useHistory } from 'react-router-dom';

toast.configure()

function Posts() {
  const history = useHistory();
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(0);
  const params = useParams();
  const [loading,setloading] =useState(false);
  function richtextToplaintext(str)
  {
    var plainString = str.replace(/<[^>]+>/g, '');
    return plainString;
  }
   function getBlogByCategory()
  {
    Axios.get(`${process.env.React_App_Api_Url}/api/blog/getblogbycategory?tag=${params.tag}`).then(blogs => {
      console.log('blog by category',blogs);
     setPosts(blogs.data.resData);
     setloading(false);
    }).catch(err => {
      setloading(false);
      toast.error('No Blog category Found');
    });
  }
  function getAllBlogs()
  {
    setPage(page+1);
    setloading(true);
    Axios.get(`${process.env.React_App_Api_Url}/api/blog/getAllblogs?page_no=${page}`).then(res => {
      // setPosts(blogs.data.blogs);
      if(params.search!=='' & params.search!==undefined & params.search!==null)
      {
        setPosts( res.data.blogs.filter((result)=>{
          if(result.title.toLowerCase().includes(params.search))
          {
            return result.title.toLowerCase().includes(params.search)
          }
          else{
            setPosts([]);                       
          }
          }))
         setloading(false);    
      }
      else{
        setloading(false);
        // debugger
        // setPosts(prevPosts=>{
        //   return [...new Set([...prevPosts,...res.data.blogs.map( b =>b.id)])]
        // })
        // if(posts.length!==0)
        // {
          setPosts(prevPosts=>[...prevPosts,...res.data.blogs])
        // }
        // else{
        //   setPosts(res.data.blogs)
        // }
        // debugger
        console.log(posts)
      }
    }).catch(err => {
      setloading(false);
      toast.error('No Blogs Found');
    });
  }
  function searchfunc()
    {
          setPosts( posts.filter((result)=>{
              return result.title.toLowerCase().includes(params.search)
          }))
          setloading(false);

    }
  function splitDate(x)
  {
      let y = x.split('T');
      return y[0];
  }
  useEffect(() => {
    setloading(true);
    window.scrollTo(0, 0);
    if(params.tag)
    {
      getBlogByCategory();
    }
    else{
      getAllBlogs();
    }
  }, [history.location.pathname])
    return (
        <>
        {loading?<div class="loading"></div>:''} 
        <div className="section" style={{"marginTop":"5%"}}>
        <div className="container">
            <div className="row">

        { posts.length!=0 ? 
            posts.map( (recentblog,index) =>{
              return(
                <>
                {
                <div className="col-md-4">
                  <div className="post">     
                  <Link className="post-img" to={`/post/${recentblog.id}`}><img src={recentblog.image} alt="" style={{"height":"176px"}}/></Link>
                    <div className="post-body">
                    <div className="post-meta">
                    {
                      (index+1)%4===0 ?<Link to={`/post/${recentblog.id}`} className="post-category cat-1">{recentblog.tag}</Link> : 
                      (index+1)%3===0 ?<Link to={`/post/${recentblog.id}`} className="post-category cat-2">{recentblog.tag}</Link>: 
                      (index+1)%2===0 ?<Link to={`/post/${recentblog.id}`} className="post-category cat-3">{recentblog.tag}</Link>: 
                      (index)%2===0 ?<Link to={`/post/${recentblog.id}`} className="post-category cat-4">{recentblog.tag}</Link>:''
                    }
                    <span className="post-date">{splitDate(recentblog.updatedAt)}</span>
                    </div>
                    <h3 className="post-title" ><Link to={`/post/${recentblog.id}`}>{recentblog.title}</Link></h3>
                <span >{richtextToplaintext(recentblog.text).substring(0, 200)}.....<Link to={`/post/${recentblog.id}`}>Read More</Link></span>
                </div>
                    </div>
                  </div>
                }         
                </>
              )

            }):'No blog found.'
          }
          <div className="col-md-12">
          <div className="section-row">
            <button className="primary-button center-block" onClick={(e)=>{getAllBlogs(e)}}>
              Load More
            </button>
          </div>
        </div>
          </div>
          </div>
          </div>

        </>
    )
}

export default Posts
